

  html.rui-night-mode .task_list2_122{
    background:#292f38 !important;
  }
  .lof{
      cursor: pointer;
  }
  .text_center_new{
      text-align: center;
  }
  .emojii_data_all{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
  .log_out_model{
    margin: 1.75rem auto !important;
    max-width: 765px !important;
  }
    .new_changes_in{
        display: inline-flex;
    }
    .message_new{
        margin-top: 5px;
        margin-left: 6px;
        margin-bottom: -10px;
    }
    // .new_changes_in p{
    //     margin-bottom: -1px;
    //     // margin-top: 5px;
    // }
  .foont_sixe{
    font-size: 50px;
    margin-bottom: -7px;
    cursor: pointer;
  }
  .my_btn_new{
    border-radius: 15px;
      padding: 4px 14px;
      text-transform: capitalize;
  }
  .my_show_row .col-lg-2{
    text-align: center;
  }
  
  html.rui-night-mode .mylab{
   border: none !important;
  }
.mynave{
    text-decoration: none;
    list-style: none;
    padding-inline-start: 10px;
    margin-top: -10px;
}
.myli{
    padding: 10px 0px;
}

.task_list2_122 {
    width: 0;
    position: fixed;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.tsa{
    visibility: visible;
    opacity: 1;

}
html.rui-night-mode .isactive{
    color: #c7d1db !important;
    white-space: nowrap;
    padding: 9.5px 30px !important; 
    }
html.rui-night-mode .isnotactive:hover{
    color: #c7d1db;  
    // border-bottom: 2px solid rgba(114,94,195,.8);
}
html.rui-night-mode .active:hover{
    color: #c7d1db !important;  
}
html.rui-night-mode .isnotactive{
    color: #626973;
    white-space: nowrap;
    padding: 9.5px 30px !important; 
    // border-bottom: 2px solid #f3f4f7;
}
.isactive{
   color: #393f49 !important;
   white-space: nowrap;
    padding: 10px 18px !important;
    background-color: #F1F4FF;
    border-radius: 10px;
   }
   .isactive svg{
    margin-top: -3px 
   }
   .isnotactive:hover{
    background-color: #F1F4FF;
    border-radius: 10px;
   }
.isnotactive:hover{
    color: #393f49;  
    // border-bottom: 2px solid rgba(114,94,195,.8);
}
.isactive:hover{
    color: #393f49 !important;  
}
.isnotactive{
    color:#a4a6a8;
    white-space: nowrap;
    padding: 10px 18px !important;
}
.isnotactive svg{
    margin-top: -3px 
}
.log{
   
    margin-left: auto;
    display: inline-flex;
}
// #626973
.log .lof{
    color: #a4a6a8 !important;
    padding: 9.5px 0px !important;  
}
.log .lof:hover{
    color: #393f49 !important;  
}
html.rui-night-mode .log .lof{
    color: #626973 !important;
    padding: 9.5px 0px !important;  
}
html.rui-night-mode .log .lof:hover{
    color: #a4a6a8 !important;  
}

.log h2{
    margin-right: 16px;
    margin-top: 7px;
   margin-bottom: 0px;
}
.ops{
    margin-top: 8px;
}
.dropss{
    margin-top: 8px;
    margin-left: 10px;
}
// @media (min-width: 1025px) and (max-width: 1280px) {

  
//   }
  @media (min-width: 1281px) {
    .ksa{
        margin-left: 10px;
        margin-right: 10px;
    }
    .myli .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
  
    html.rui-night-mode .myli .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }

    }
  
    // @media (max-width: 1490px) and (min-width: 1440px) {
  

    // }
    // @media (min-width: 1500px) and (max-width: 1550px) {
  
  
    // }
  @media (max-width: 576px){


    .log .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
    .log .lof:hover{
        color: #393f49 !important;  
    }
    html.rui-night-mode .log .lof{
        color: #626973 !important;
        padding: 9.5px 0px !important;  
    }
    html.rui-night-mode .log .lof:hover{
        color: #a4a6a8 !important;  
    }
    .myli .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
  
    html.rui-night-mode .myli .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
    .dropss{
        margin-top: 0px;
        margin-left: 0px;  
    }
    .isactive{
        color: #393f49;
        white-space: nowrap;
     //    border-bottom: 2px solid rgba(114,94,195,.8);
     //    border-bottom-color: rgba(114,94,195,.8);
        padding: 9.5px 0px !important; 
        }
    .isnotactive{
        color:#a4a6a8;
        white-space: nowrap;
        padding: 9.5px 0px !important; 
        // border-bottom: 2px solid #f3f4f7;
    }
    .log .lof {
        padding: 9.5px 2px !important;
    }
    html.rui-night-mode .isactive{
        color: #a4a6a8;
        white-space: nowrap;
     //    border-bottom: 2px solid rgba(114,94,195,.8);
     //    border-bottom-color: rgba(114,94,195,.8);
        padding: 9.5px 0px !important; 
        }
    html.rui-night-mode  .isnotactive{
        color:#a4a6a8;
        white-space: nowrap;
        padding: 9.5px 0px !important; 
        // border-bottom: 2px solid #f3f4f7;
    }
    html.rui-night-mode  .log .lof {
        padding: 9.5px 2px !important;
    }
  }
  @media (max-width: 660px){
    .myli .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
  
    html.rui-night-mode .myli .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
    .log .lof{
        color: #a4a6a8 !important;
        padding: 9.5px 0px !important;  
    }
    .log .lof:hover{
        color: #393f49 !important;  
    }
    html.rui-night-mode .log .lof{
        color: #626973 !important;
        padding: 9.5px 0px !important;  
    }
    html.rui-night-mode .log .lof:hover{
        color: #a4a6a8 !important;  
    }

    .dropss{
        margin-top: 0px;
        margin-left: 0px;  
    }
    .isactive{
        color: #393f49;
        white-space: nowrap;
     //    border-bottom: 2px solid rgba(114,94,195,.8);
     //    border-bottom-color: rgba(114,94,195,.8);
        padding: 9.5px 0px !important; 
        }
    .isnotactive{
        color:#a4a6a8;
        white-space: nowrap;
        padding: 9.5px 0px !important; 
        // border-bottom: 2px solid #f3f4f7;
    }
    .log .lof {
        padding: 9.5px 2px !important;
    }
    html.rui-night-mode .isactive{
        color: #a4a6a8;
        white-space: nowrap;
     //    border-bottom: 2px solid rgba(114,94,195,.8);
     //    border-bottom-color: rgba(114,94,195,.8);
        padding: 9.5px 0px !important; 
        }
    html.rui-night-mode  .isnotactive{
        color:#a4a6a8;
        white-space: nowrap;
        padding: 9.5px 0px !important; 
        // border-bottom: 2px solid #f3f4f7;
    }
    html.rui-night-mode  .log .lof {
        padding: 9.5px 2px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .isactive{
        color: #393f49;
        white-space: nowrap;
     //    border-bottom: 2px solid rgba(114,94,195,.8);
     //    border-bottom-color: rgba(114,94,195,.8);
        }
    .isnotactive{
        color:#a4a6a8;
        white-space: nowrap;
        padding: 9.5px 0px !important; 
        // border-bottom: 2px solid #f3f4f7;
    }
    html.rui-night-mode .isactive{
        color: #a4a6a8;
        white-space: nowrap;
     //    border-bottom: 2px solid rgba(114,94,195,.8);
     //    border-bottom-color: rgba(114,94,195,.8);
        padding: 9.5px 0px !important; 
        }
    html.rui-night-mode  .isnotactive{
        color:#a4a6a8;
        white-space: nowrap;
        padding: 9.5px 0px !important; 
        // border-bottom: 2px solid #f3f4f7;
    }
  
  
  
    }
    .employeeNavBar{
        margin-right: 30px;
    }